<script>
import moment from "moment";
import { mapActions, mapState } from "vuex";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

export default {
  props: ["urlSiguiente"],
  data() {
    return {
      validando: false,
      telefonoSeleccionado: "",
      codigoVerificacion: "",
      noCorreo: false,
      cliente: false,
      altaTarjeta: false,
      activacion: false,
      preregistro: false,
      saldo: 0,
      descripcion: "",
      metodo: "sms",
      nombreSender: "",
      correoSender: "",
      nombreCliente: "",
      correoCliente: "",
      tarjeta: "",
      asuntoCorreo: "",
      linkRegistro: "",
      templateCorreo: 0,
      contador: 0,
      intervalo: null,
    };
  },
  mounted() {
    this.cargarTelefono();

    if (this.activacion) {
      console.log("activacion");
      this.cargarDatosActivacion();
    }
  },
  computed: {
    ...mapState("autenticacion", ["usuarioActual"]),
  },
  methods: {
    ...mapActions("validacion", [
      "enviarCodigoTelefono",
      "validarCodigoTelefono",
      "guardarDatosValidadosTarjetahabiente",
      "guardarDatosTarjetahabienteHasura",
      "buscarTarjetahabienteFirebase",
      "consultarConteoCli",
      "consultarIdCli",
      "consultarTarjetahabienteHasura",
      "actualizarDatosTarjetahabienteHasura",
      "guardarPreregistroTarjeta",
      "activarTarjeta",
      "enviarCorreoMailjet",
      "enviarCodigoTwilio",
      "validarCodigoTwilio"
    ]),
    cargarDatosActivacion() {
      var datosActivacion = localStorage.getItem("activacion");
      if (datosActivacion) {
        datosActivacion = JSON.parse(datosActivacion);
        this.saldo = datosActivacion?.saldo;
        this.descripcion = datosActivacion?.descripcion;
      } else {
        window.location.replace("https://www.superkompras.com.mx");
      }
    },
    cargarTelefono() {
      var datosUsuario = localStorage.getItem("datosUsuario");
      if (datosUsuario) {
        datosUsuario = JSON.parse(datosUsuario);
        console.log(datosUsuario);
        console.log(datosUsuario.telefono);
        this.telefonoSeleccionado = datosUsuario?.telefono;
        this.noCorreo = datosUsuario?.noCorreo;
        this.altaTarjeta = datosUsuario?.altaTarjeta;
        this.activacion = datosUsuario?.activacion;
        this.preregistro = datosUsuario?.preregistro;
        this.cliente = datosUsuario?.cliente;
        this.tarjeta = datosUsuario?.tarjeta;

        console.log(this.telefonoSeleccionado);

        this.mostrarAlertaConfirmarTelefono();

        // this.mostrarAlertaMetodosReenvio();**************
        // this.mostrarAlertaEnviandoCorreo()

      } else {
        this.$router.replace("/verificar");
      }
    },
    async enviarCodigo(metodo) {
      try {
        console.log(this.telefonoSeleccionado);
        const resCodigo = await this.enviarCodigoTwilio({
          metodo: metodo,
          telefono: this.telefonoSeleccionado,
        });
        console.log(this.telefonoSeleccionado);
        console.log(resCodigo);
        if (resCodigo) {
          toast.success(" ¡Mensaje de confirmación enviado! ", {
            hideProgressBar: true,
            autoClose: 2000,
            theme: "colored",
          });
        }
      } catch (error) {
        console.log(error);
        this.$swal({
          icon: error.code ? "error" : "warning",
          type: error.code ? "error" : "warning",
          title: error.code ? error.code : "Oops...",
          text: error.msg,
        });
      }
    },
    verificarCodigoTel() {
      this.validando = true;
      const errores = [];

      if (this.codigoVerificacion == "") {
        errores.push({ msg: "Introduzca un código de confirmación" });
      }

      if (
        isNaN(this.codigoVerificacion) ||
        this.codigoVerificacion.length != 6
      ) {
        errores.push({ msg: "Código inválido" });
      }

      console.log(errores);
      if (errores.length > 0) {
        this.$swal({
          icon: "warning",
          type: "warning",
          title: "Oops...",
          text: errores[0].msg,
        });
        this.validando = false;
      } else {
        this.verificarCodigo();
      }
    },
    async verificarCodigo() {
      try {
        // setTimeout(() => {
        //   this.$swal({
        //     icon: "success",
        //     type: "success",
        //     title: "Teléfono validado correctamente",
        //     confirmButtonText: "Continuar",
        //   }).then((result) => {
        //     console.log(result);
        //     this.guardarTarjetahabiente();
        //     // if (result.value) {
        //     //   this.$router.push(this.urlSiguiente);
        //     // }
        //   });
        //   this.validando = false;
        // }, 3000);

        const resValidacion = await this.validarCodigoTwilio({
          telefono: this.telefonoSeleccionado,
          codigo: this.codigoVerificacion,
        });
        console.log(resValidacion);
        if (resValidacion) {
          this.$swal({
            icon: "success",
            type: "success",
            title: "Teléfono validado correctamente",
            allowOutsideClick: false,
            confirmButtonText: "Continuar",
          }).then((result) => {
            console.log(result);
            if (this.preregistro) {
              this.guardarPreregistro();
            } else {
              this.guardarTarjetahabiente();
            }
          });
        }
      } catch (error) {
        console.log(error);
        this.$swal({
          icon: error.code ? "error" : "warning",
          type: error.code ? "error" : "warning",
          title: error.code ? error.code : "Oops...",
          text: error.msg,
        });
        this.validando = false;
      }
      // finally {
      //     console.log("RESET");
      //     validando.value = false;
      // }
    },
    mostrarAlertaConfirmarTelefono() {
      this.$swal({
        title: "¿Desea continuar con la verificación?",
        text: `El teléfono proporcionado es: +52 ${this.telefonoSeleccionado}`,
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Regresar",
        showDenyButton: true,
        denyButtonColor: "#d33",
        allowOutsideClick: false,
        confirmButtonText: "Si, continuar!",
        denyButtonText: "Cambiar número",
      }).then((result) => {
        if (result.isConfirmed) {
          this.enviarCodigo("sms");
        } else if (result.isDenied) {
          this.mostrarAlertaNuevoTelefono();
        } else {
          if (this.noCorreo) {
            if (this.altaTarjeta) {
              this.$router.replace("/alta-tarjeta");
            } else if(this.activacion){
              this.$router.replace(`/cliente/activacion-tarjeta/${this.tarjeta}`);
            }else {
              this.$router.replace("/verificar");
            }
          } else {
            this.$router.replace("/verificar-correo");
          }
        }
      });
    },
    mostrarAlertaNuevoTelefono() {
      this.$swal({
        text: "Escriba su número de teléfono:",
        input: "text",
        showDenyButton: true,
        denyButtonColor: "#d33",
        allowOutsideClick: false,
        confirmButtonColor: "var(--theme-deafult)",
        denyButtonText: "Cancelar",
      }).then((result) => {
        console.log(result);
        if (result.isConfirmed) {
          this.validarNuevoTelefono(result.value);
        } else {
          this.mostrarAlertaConfirmarTelefono();
        }
      });
    },
    validarNuevoTelefono(telefonoValidar) {
      const errores = [];

      if (telefonoValidar == "") {
        errores.push({ msg: "Aún hay campos sin llenar" });
      }

      if (isNaN(telefonoValidar) || telefonoValidar.length != 10) {
        errores.push({ msg: "Teléfono inválido" });
      }

      console.log(errores);
      if (errores.length > 0) {
        this.$swal({
          icon: "error",
          type: "warning",
          title: "Oops...",
          text: errores[0].msg,
        }).then((result) => {
          this.mostrarAlertaNuevoTelefono();
        });
      } else {
        this.telefonoSeleccionado = telefonoValidar;
        let objetoDatosUsuario = JSON.parse(
          localStorage.getItem("datosUsuario")
        );
        console.log(objetoDatosUsuario);
        objetoDatosUsuario.telefono = this.telefonoSeleccionado;
        localStorage.setItem(
          "datosUsuario",
          JSON.stringify(objetoDatosUsuario)
        );
        this.mostrarAlertaConfirmarTelefono();
      }
    },
    async guardarTarjetahabiente() {
      try {
        let empleado = localStorage.getItem("empleado");
        empleado = JSON.parse(empleado);

        let datosUsuario = localStorage.getItem("datosUsuario");
        datosUsuario = JSON.parse(datosUsuario);
        console.log(datosUsuario);
        const fechaActual = moment();
        const formatoFechaActual = fechaActual.format("YYYY-MM-DD");

        let id_cli = "";

        if (datosUsuario["idCli"] && (!this.altaTarjeta || !this.activacion)) {
          id_cli = datosUsuario["idCli"];
        } else {
          id_cli = (await this.consultarConteoCli()) + 1;
        }

        // if (this.altaTarjeta) {
        //   id_cli = (await this.consultarConteoCli()) + 1;
        // } else {
        //   id_cli = await this.consultarIdCli({
        //     nombre: datosUsuario["nombre"],
        //     apellidoPaterno: datosUsuario["apellidoPaterno"],
        //     apellidoMaterno: datosUsuario["apellidoMaterno"],
        //   });
        // }

        const dataTarjetahabiente = {
          apellidoM: datosUsuario["apellidoMaterno"],
          apellidoP: datosUsuario["apellidoPaterno"],
          dioalta:
            this.usuarioActual && !this.cliente
              ? this.usuarioActual.nombre
              : "NULL",
          email: datosUsuario["correo"],
          fechanacim: datosUsuario["fechaNacimiento"],
          nombre: datosUsuario["nombre"],
          saldoc:
            this.saldo && this.cliente
              ? this.saldo
              : this.cliente
                ? 0
                : datosUsuario["puntos"],
          sexo: datosUsuario["sexo"],
          tarjeta: datosUsuario["tarjeta"],
          telefono: datosUsuario["telefono"],
          verificoEmail: datosUsuario["noCorreo"] ? false : true,
          cp: datosUsuario["codigoPostal"],
          fechaaltat: datosUsuario["fechaAltat"]
            ? datosUsuario["fechaAltat"]
            : formatoFechaActual,
          promotor: this.usuarioActual?.rol == "promotor" ? true : false,
          division: this.cliente ? 0 : this.usuarioActual?.division,
          userID: this.cliente ? "" : this.usuarioActual?.uid,
          id_cli: id_cli,
          preregistro:
            this.descripcion && this.cliente
              ? this.descripcion
              : empleado
                ? empleado["usuario"]
                : this.usuarioActual && !this.cliente
                  ? this.usuarioActual.nombre
                  : "",
          recomendo:
            datosUsuario["recomendo"] == "SIN RECOMENDACIÓN"
              ? "NULL"
              : datosUsuario["recomendo"],
        };

        console.log(dataTarjetahabiente);

        const res = await this.guardarDatosValidadosTarjetahabiente(
          dataTarjetahabiente
        );

        await this.guardarTarjetahabienteHasura(datosUsuario["tarjeta"]);

        if (this.activacion) {
          const fechaActualActivacion = moment().format("YYYY-MM-DDTHH:mm:ss");
          console.log(fechaActualActivacion);
          await this.activarTarjeta({
            fecha_activacion: fechaActualActivacion,
            tarjeta: datosUsuario["tarjeta"],
          });
        }

        localStorage.removeItem("activacion");

        // console.log(res);
        this.$swal({
          icon: "success",
          type: "success",
          title: "Datos actualizados correctamente!",
          allowOutsideClick: false,
          confirmButtonText: "Continuar",
        }).then((result) => {
          console.log(result);
          if (result.value) {
            if (this.altaTarjeta) {
              this.$router.replace("alta-tarjeta");
            } else if (this.activacion) {
              window.location.replace("https://www.superkompras.com.mx");
            } else {
              this.$router.push(this.urlSiguiente);
            }
          }
        });
      } catch (error) {
        console.log(error);
        this.$swal({
          icon: error.code ? "error" : "warning",
          type: error.code ? "error" : "warning",
          title: error.code ? error.code : "Oops...",
          text: error.msg,
        });
      } finally {
        this.validando = false;
      }
    },
    async guardarTarjetahabienteHasura(id) {
      console.log("Guardando en HASURA");
      const dataTarjetahabiente = await this.buscarTarjetahabienteFirebase(id);

      console.log(dataTarjetahabiente);
      if (dataTarjetahabiente) {
        console.log(dataTarjetahabiente);
        let id_cli = "";

        if (
          dataTarjetahabiente["id_cli"] &&
          (!this.altaTarjeta || !this.activacion)
        ) {
          id_cli = dataTarjetahabiente["id_cli"];
        } else {
          id_cli = (await this.consultarConteoCli()) + 1;
        }
        console.log(dataTarjetahabiente);
        const dataTarjetahabienteHasura = {
          Record_Id: dataTarjetahabiente["tarjeta"],
          apellidoM: dataTarjetahabiente["apellidoM"],
          apellidoP: dataTarjetahabiente["apellidoP"],
          dioalta: dataTarjetahabiente["dioalta"],
          email: dataTarjetahabiente["email"],
          fechanacim: dataTarjetahabiente["fechanacim"],
          nombre: dataTarjetahabiente["nombre"],
          saldoc: dataTarjetahabiente["saldoc"]
            ? parseFloat(dataTarjetahabiente["saldoc"])
            : 0.0,
          sexo: dataTarjetahabiente["sexo"],
          tarjeta: dataTarjetahabiente["tarjeta"],
          telefono: parseFloat(dataTarjetahabiente["telefono"]),
          verificoemail: dataTarjetahabiente["verificoEmail"]
            ? "true"
            : "false",
          cp: parseInt(dataTarjetahabiente["cp"]),
          fechaalta: dataTarjetahabiente["fechaaltat"],
          promotor: dataTarjetahabiente["promotor"] ? "true" : "false",
          division: parseInt(dataTarjetahabiente["division"]),
          userID: dataTarjetahabiente["userIDTimestamp"].slice(0, -13),
          // fechaverfi: "1999-01-01",
          fechaverfi: dataTarjetahabiente["fechaverif"],
          actcte: "true",
          id_cli: dataTarjetahabiente["id_cli"]
            ? parseInt(dataTarjetahabiente["id_cli"])
            : id_cli,
          preregistro: dataTarjetahabiente["preregistro"],
          recomendo: dataTarjetahabiente["recomendo"],
        };

        console.log("DATA TARJETAHABIENTE HASURA");
        console.log(dataTarjetahabienteHasura);

        const tarjetahabienteEncontrado =
          await this.consultarTarjetahabienteHasura(id);
        console.log("HOLA");
        console.log(tarjetahabienteEncontrado);

        if (tarjetahabienteEncontrado) {
          console.log("OP2");
          const responseActualizarHasura =
            await this.actualizarDatosTarjetahabienteHasura(
              dataTarjetahabienteHasura
            );

          console.log(responseActualizarHasura);
        } else {
          console.log("OP1");
          const responseGuardarHasura =
            await this.guardarDatosTarjetahabienteHasura(
              dataTarjetahabienteHasura
            );

          console.log(responseGuardarHasura);
        }
      }
    },

    async guardarPreregistro() {
      try {
        let datosUsuario = localStorage.getItem("datosUsuario");
        datosUsuario = JSON.parse(datosUsuario);

        const timestamp = moment().format("x");

        const fechaPreregistro = moment().format("DD/MM/YYYY h:mm a");

        const dataPreregistro = {
          preregistroId: timestamp,
          apellidoMaterno: datosUsuario["apellidoMaterno"],
          apellidoPaterno: datosUsuario["apellidoPaterno"],
          email: datosUsuario["correo"],
          fechanacim: datosUsuario["fechaNacimiento"],
          nombre: datosUsuario["nombre"],
          sexo: datosUsuario["sexo"],
          telefono: datosUsuario["telefono"],
          verificoEmail: true,
          cp: datosUsuario["codigoPostal"],
          fechaPreregistro: fechaPreregistro,
          folio: timestamp,
          sucursal: datosUsuario["sucursal"],
        };

        console.log(dataPreregistro);
        await this.guardarPreregistroTarjeta(dataPreregistro);

        const dataFolio = {
          folio: timestamp,
        };
        localStorage.setItem("folio", JSON.stringify(dataFolio));

        this.$swal({
          icon: "success",
          type: "success",
          title: "Pre registro completado correctamente!",
          allowOutsideClick: false,
          confirmButtonText: "Continuar",
        }).then((result) => {
          console.log(result);
          if (result.value) {
            this.$router.push("/cliente/folio");
          }
        });
      } catch (error) {
        console.log(error);
        this.$swal({
          icon: error.code ? "error" : "warning",
          type: error.code ? "error" : "warning",
          title: error.code ? error.code : "Oops...",
          text: error.msg,
        });
      } finally {
        this.validando = false;
      }
    },
    mostrarAlertaMetodosReenvio() {
      this.$swal({
        html: `<div class="d-flex-column justify-content-around mt-3">
    
    <button
      class="btn btn-success"
      type="button"
      data-bs-original-title=""
      title=""
      id="sms"
      value="sms"

    >
    SMS
    </button>
    <button
      class="btn btn-light"
      type="button"
      data-bs-original-title=""
      title=""
      id="llamada"
      value="llamada"

    >
    Llamada
    </button>
  </div>
    `,
        title: "¿Por qué medio desea recibir su código de verificación?",
        confirmButtonText: "Aceptar",
        willOpen: () => {
          const sms = document.getElementById("sms");
          const llamada = document.getElementById("llamada");

          switch (this.metodo) {
            case "sms":
              sms.classList.add("btn-success");
              sms.classList.remove("btn-light");

              llamada.classList.add("btn-light");
              llamada.classList.remove("btn-success");

              break;
            case "call":
              llamada.classList.add("btn-success");
              llamada.classList.remove("btn-light");

              sms.classList.add("btn-light");
              sms.classList.remove("btn-success");

              break;
          }


          sms.addEventListener("click", () => {
            sms.classList.add("btn-success");
            sms.classList.remove("btn-light");

            llamada.classList.add("btn-light");
            llamada.classList.remove("btn-success");


            this.metodo = "sms";
          });

          llamada.addEventListener("click", () => {
            llamada.classList.add("btn-success");
            llamada.classList.remove("btn-light");

            sms.classList.add("btn-light");
            sms.classList.remove("btn-success");


            this.metodo = "call";
          });
        },
      }).then((result) => {
        if (result.isConfirmed) {
          this.enviarCodigo(this.metodo);
        }
      });
    },
    beforeUnmount() {
      if (this.intervalo) {
        clearInterval(this.intervalo);
      }
    },
    reenviarCodigo() {
      this.mostrarAlertaMetodosReenvio(); // Llama a la función de reenvío

      this.contador = 60; // Inicia el contador en 60 segundos
      this.intervalo = setInterval(() => {
        if (this.contador > 0) {
          this.contador--;
        } else {
          clearInterval(this.intervalo); // Detiene el contador cuando llega a 0
        }
      }, 1000);
    },
  },
};
</script>
<template>
  <div class="container">
    <div class="page-title">
      <div class="row">
        <div class="col">
          <h3>Verificar teléfono</h3>
        </div>
      </div>
    </div>
  </div>
  <div class="container d-flex justify-content-center">
    <div class="card w-100 px-5 py-2" style="max-width: 600px">
      <div class="card-header pb-0">
        <h2>Confirme su número de teléfono</h2>
      </div>

      <form @submit.prevent="verificarCodigoTel()">
        <div class="card-body" style="height: 25rem">
          <div class="d-flex justify-content-between flex-column" style="height: 100%">
            <h5 class="text-muted">
              Le hemos enviado un mensaje de texto con el código de confirmación
              al número:
              <span class="f-w-700 txt-dark">{{ telefonoSeleccionado }}</span>. Por razones de seguridad, el código
              caducará dentro de 15
              minutos.
            </h5>

            <input class="form-control digits" type="text" placeholder="Código de verificación"
              v-model.trim="codigoVerificacion" />

            <button v-if="!validando" class="btn btn-primary" type="submit">
              <span>Verificar teléfono</span>
            </button>
            <div v-else class="mb-2">
              <div class="d-flex justify-content-center mb-3">
                <h6>Verificando...</h6>
              </div>
              <div class="loader-box" style="height: 1rem">
                <div class="loader-7"></div>
              </div>
            </div>
            <div>
              <span class="mt-0" for="nota">Si no ha llegado el código de verificación dale click al
                siguiente enlace:
                <a v-if="contador === 0" class="d-inline" style="cursor: pointer; color: blue;" @click="reenviarCodigo">
                  Reenviar código de verificación
                </a>
                <span v-else style="color: gray;">Reenviar en {{ contador }}s</span>
              </span>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
